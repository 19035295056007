/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_firstload = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/*
		Trigger: modal and popup preloader
		*/
		images.preload();
		modal.onready();
		popup.onready();
		form.onready();
		if(pollConnection === 1) { poll_connection.onready(); }
		
		/*
		Trigger: onready classes
		*/
		specific_mobilemenu.onready();

		/*
		Accordeon
		*/
		$(document).on('click','.js-accordeon-item',function() {

			$('.js-accordeon-item-inside').stop().slideUp();
			$('.js-accordeon-item').find('h2').removeClass('rotated');

			if ($(this).find('.js-accordeon-item-inside').is(':visible')) {
				$(this).find('.js-accordeon-item-inside').stop().slideUp();
				$(this).find('h2').removeClass('rotated');
			} else {
				$(this).find('.js-accordeon-item-inside').stop().slideDown();
				$(this).find('h2').addClass('rotated');
			}

		});

		/*
		Colorbox
		*/
		$('.gallery').colorbox({
			rel:'gallery',
			width:'75%',
			onComplete: function () {
                $(this).colorbox.resize();
          	}  
		});
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_firstload); 