/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	01-03-2018
	- [ADDED] Added document.ready to onready.
	26-02-2018
	- [ADDED] Open en Close methods are extended with new function to prevent body scrolling when menu is open
2017
	30-11-2017
	- [CHANGE] .js-menu-button is now changed to .js-mobile-menu-button
	15-10-2017
	- [METHOD] Add and remove .active class to the js-menu-button.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_video = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.onready = function() {

		/*
		Overlay: Tablet or Phone
		*/	
		if(
			url[1] == '' && 
			_device == 'smartphone' ||
			url[1] == '' && 
			_device == 'tablet'
		) {
			$('.js-overlay').show();
		}

		/*
		Autoplay on desktop
		*/	
		if(
			_device == 'desktop'
		) {

			setTimeout(function() {
				
				//$('.js-video-desktop').fadeIn(200);
				
				setTimeout(function(){ 
					$('.js-video-1').trigger('play');
				},1000);

				setTimeout(function(){ 
					$('.js-video-2').trigger('play');
				},200);

				setTimeout(function(){ 
					$('.js-video-3').trigger('play');
				},800);

			},200);
		}

		/*
		Click
		*/
		$('.js-overlay').on('click',function() { 
			$(this).fadeOut();
			$('.js-video-mobile').find('video').trigger('play');
		});

	};

		
}).apply(specific_video); 